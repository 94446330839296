import React from 'react';

const ReactConsulting = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75548 35.3969C8.492 35.3903 7.28214 34.8854 6.38871 33.9919C5.49528 33.0985 4.99045 31.8887 4.98389 30.6252V26.9279C4.98389 25.6624 5.48662 24.4487 6.38147 23.5539C7.27631 22.659 8.48997 22.1562 9.75548 22.1562C11.021 22.1562 12.2346 22.659 13.1295 23.5539C14.0243 24.4487 14.5271 25.6624 14.5271 26.9279V30.6252C14.5271 31.2518 14.4037 31.8724 14.1639 32.4513C13.9241 33.0302 13.5726 33.5562 13.1295 33.9993C12.6864 34.4423 12.1604 34.7939 11.5815 35.0337C11.0026 35.2735 10.3821 35.3969 9.75548 35.3969ZM9.75548 23.4054C8.82126 23.4054 7.92529 23.7767 7.2647 24.4373C6.60411 25.0979 6.23299 25.9937 6.23299 26.9279V30.6252C6.23299 31.5594 6.60411 32.4555 7.2647 33.1161C7.92529 33.7767 8.82126 34.1478 9.75548 34.1478C10.6897 34.1478 11.5857 33.7767 12.2463 33.1161C12.9068 32.4555 13.278 31.5594 13.278 30.6252V26.9279C13.2846 26.4611 13.1984 25.9979 13.0243 25.5648C12.8503 25.1316 12.5918 24.7371 12.2641 24.4047C11.9363 24.0723 11.5458 23.8084 11.1151 23.6283C10.6845 23.4481 10.2223 23.3556 9.75548 23.3556V23.4054Z"
      fill="#475467"
    />
    <path
      d="M14.8643 47.0212H4.4218C3.51409 47.0212 2.64355 46.6606 2.0017 46.0188C1.35985 45.3769 0.999268 44.5064 0.999268 43.5986V39.7513C1.04562 39.2816 1.19783 38.8287 1.44447 38.4263C1.6911 38.0239 2.02577 37.6826 2.42326 37.4281C3.23114 36.8207 4.14653 36.3714 5.1213 36.104L8.34401 35.2047C8.50302 35.1583 8.67393 35.1769 8.81917 35.2565C8.9644 35.3362 9.07207 35.4702 9.11845 35.6292C9.16482 35.7882 9.14613 35.9591 9.06649 36.1043C8.98684 36.2496 8.85277 36.3574 8.69375 36.4038L5.44608 37.3031C4.63102 37.5291 3.86749 37.9107 3.1977 38.4272C2.57315 38.9018 2.2234 39.3766 2.2234 39.7513V43.5986C2.22998 44.1774 2.46448 44.73 2.87605 45.1369C3.28762 45.5438 3.84305 45.7721 4.4218 45.7721H14.8643C15.4431 45.7721 15.9985 45.5438 16.4101 45.1369C16.8217 44.73 17.0562 44.1774 17.0628 43.5986V39.7513C17.0628 39.3766 16.713 38.9018 16.0885 38.4272C15.4187 37.9107 14.6551 37.5291 13.8401 37.3031L10.6923 36.4288C10.5333 36.3824 10.3992 36.2746 10.3196 36.1293C10.2399 35.9841 10.2213 35.8132 10.2676 35.6542C10.2906 35.5755 10.3288 35.502 10.3802 35.438C10.4315 35.374 10.495 35.321 10.5669 35.2816C10.6388 35.2421 10.7178 35.2173 10.7993 35.2084C10.8809 35.1994 10.9633 35.2067 11.0421 35.2297L14.1648 36.104C15.1416 36.3662 16.058 36.8159 16.8629 37.4281C17.2638 37.6813 17.6023 38.0215 17.8532 38.4238C18.1041 38.8261 18.2609 39.2799 18.3119 39.7513V43.5986C18.3119 44.0502 18.2225 44.4974 18.049 44.9142C17.8754 45.3311 17.6211 45.7095 17.3006 46.0276C16.9801 46.3458 16.5999 46.5972 16.1817 46.7678C15.7636 46.9383 15.3159 47.0245 14.8643 47.0212Z"
      fill="#475467"
    />
    <path
      d="M8.51029 36.425C8.34465 36.425 8.1858 36.3592 8.06868 36.2421C7.95155 36.1249 7.88574 35.9661 7.88574 35.8005V34.7261C7.88574 34.5605 7.95155 34.4017 8.06868 34.2845C8.1858 34.1674 8.34465 34.1016 8.51029 34.1016C8.67594 34.1016 8.83482 34.1674 8.95195 34.2845C9.06907 34.4017 9.13485 34.5605 9.13485 34.7261V35.8005C9.13485 35.9661 9.06907 36.1249 8.95195 36.2421C8.83482 36.3592 8.67594 36.425 8.51029 36.425Z"
      fill="#475467"
    />
    <path
      d="M10.8785 36.45C10.7128 36.45 10.554 36.3842 10.4368 36.2671C10.3197 36.1499 10.2539 35.9911 10.2539 35.8255V34.7261C10.2539 34.5605 10.3197 34.4017 10.4368 34.2845C10.554 34.1674 10.7128 34.1016 10.8785 34.1016C11.0441 34.1016 11.203 34.1674 11.3201 34.2845C11.4372 34.4017 11.503 34.5605 11.503 34.7261V35.8255C11.503 35.9911 11.4372 36.1499 11.3201 36.2671C11.203 36.3842 11.0441 36.45 10.8785 36.45Z"
      fill="#475467"
    />
    <path
      d="M9.65613 40.7508C8.7317 40.6142 7.89667 40.1232 7.32782 39.3819C6.75897 38.6405 6.50094 37.707 6.60831 36.7788C6.58427 36.5379 6.58427 36.2951 6.60831 36.0542C6.65041 35.9098 6.74315 35.7855 6.86951 35.7041C6.99587 35.6226 7.14735 35.5895 7.29618 35.6108C7.44501 35.632 7.5812 35.7064 7.6797 35.82C7.77821 35.9335 7.83241 36.0786 7.83241 36.2289C7.82054 36.4119 7.82054 36.5957 7.83241 36.7788C7.83241 38.2527 8.65682 39.5017 9.63112 39.5017C10.6054 39.5017 11.4298 38.2527 11.4298 36.7788C11.4417 36.5957 11.4417 36.4119 11.4298 36.2289C11.4048 36.143 11.3985 36.0528 11.4113 35.9642C11.4242 35.8757 11.456 35.7909 11.5045 35.7157C11.553 35.6405 11.617 35.5767 11.6924 35.5284C11.7677 35.4801 11.8525 35.4487 11.9411 35.436C12.0297 35.4234 12.1199 35.4297 12.2058 35.4549C12.2916 35.4802 12.371 35.5238 12.4386 35.5824C12.5062 35.641 12.5604 35.7136 12.5976 35.795C12.6347 35.8764 12.654 35.9647 12.654 36.0542C12.678 36.2951 12.678 36.5379 12.654 36.7788C12.7625 37.6996 12.5112 38.6265 11.9527 39.3666C11.3941 40.1067 10.5714 40.6027 9.65613 40.7508Z"
      fill="#475467"
    />
    <path
      d="M11.2744 27.5607C10.8244 27.5596 10.3803 27.4571 9.97532 27.261C9.5694 27.0663 9.21121 26.785 8.92607 26.4367C8.65127 26.1868 8.52636 26.0368 8.30152 25.9869C8.07668 25.9369 6.95249 26.3117 6.07812 27.286C6.01985 27.3464 5.95003 27.3946 5.87276 27.4275C5.7955 27.4603 5.71241 27.4772 5.62846 27.4772C5.5445 27.4772 5.46141 27.4603 5.38415 27.4275C5.30688 27.3946 5.23702 27.3464 5.17876 27.286C5.06432 27.1692 5.00024 27.0121 5.00024 26.8487C5.00024 26.6852 5.06432 26.5284 5.17876 26.4117C6.45285 25.0376 7.55208 24.5129 8.60133 24.7377C9.09216 24.8784 9.5281 25.1664 9.85044 25.5624C10.0333 25.7917 10.2633 25.9791 10.5249 26.1119C11.0495 26.3367 11.849 26.4866 13.5478 25.5123C13.6185 25.4609 13.6991 25.4248 13.7846 25.4065C13.87 25.3883 13.9584 25.3882 14.0439 25.4062C14.1294 25.4242 14.2102 25.4599 14.281 25.5111C14.3518 25.5623 14.4111 25.628 14.455 25.7035C14.4989 25.7791 14.5265 25.8628 14.536 25.9496C14.5454 26.0365 14.5366 26.1244 14.5099 26.2076C14.4833 26.2909 14.4395 26.3678 14.3813 26.433C14.3231 26.4982 14.252 26.5504 14.1723 26.5864C13.3073 27.1572 12.3086 27.493 11.2744 27.5607Z"
      fill="#475467"
    />
    <path
      d="M39.2257 35.3969C37.9622 35.3903 36.7524 34.8854 35.8589 33.9919C34.9655 33.0985 34.4607 31.8887 34.4541 30.6252V26.9279C34.4541 25.6624 34.9568 24.4487 35.8517 23.5539C36.7465 22.659 37.9602 22.1562 39.2257 22.1562C40.4912 22.1562 41.7049 22.659 42.5997 23.5539C43.4945 24.4487 43.9972 25.6624 43.9972 26.9279V30.6252C43.9907 31.8887 43.4859 33.0985 42.5925 33.9919C41.699 34.8854 40.4892 35.3903 39.2257 35.3969ZM39.2257 23.4054C38.2915 23.4054 37.3955 23.7767 36.7349 24.4373C36.0743 25.0979 35.7032 25.9937 35.7032 26.9279V30.6252C35.7032 31.5594 36.0743 32.4555 36.7349 33.1161C37.3955 33.7767 38.2915 34.1478 39.2257 34.1478C40.1599 34.1478 41.0559 33.7767 41.7165 33.1161C42.3771 32.4555 42.7482 31.5594 42.7482 30.6252V26.9279C42.7548 26.4611 42.6685 25.9979 42.4945 25.5648C42.3204 25.1316 42.062 24.7371 41.7343 24.4047C41.4065 24.0723 41.016 23.8084 40.5853 23.6283C40.1546 23.4481 39.6925 23.3556 39.2257 23.3556V23.4054Z"
      fill="#475467"
    />
    <path
      d="M44.3432 47.0212H33.9006C33.4491 47.0245 33.0013 46.9383 32.5832 46.7678C32.1651 46.5973 31.7848 46.3458 31.4644 46.0277C31.1439 45.7095 30.8896 45.3311 30.716 44.9143C30.5424 44.4974 30.4531 44.0502 30.4531 43.5987V39.7513C30.5041 39.2799 30.6608 38.8262 30.9117 38.4239C31.1626 38.0215 31.5012 37.6813 31.9021 37.4282C32.6978 36.8161 33.6061 36.3662 34.5752 36.104L37.8228 35.2047C37.9016 35.1818 37.984 35.1745 38.0656 35.1834C38.1471 35.1923 38.2261 35.2171 38.298 35.2566C38.3699 35.296 38.4334 35.3494 38.4847 35.4133C38.5361 35.4773 38.5743 35.5505 38.5973 35.6292C38.636 35.7914 38.6109 35.9623 38.5272 36.1065C38.4435 36.2506 38.3076 36.3571 38.1476 36.4038L34.9249 37.3031C34.1022 37.529 33.3304 37.9105 32.6515 38.4272C32.052 38.9019 31.7022 39.3766 31.7022 39.7513V43.5987C31.7022 43.8862 31.7592 44.1707 31.87 44.4361C31.9808 44.7014 32.1431 44.9423 32.3476 45.1445C32.5521 45.3466 32.7946 45.5061 33.0612 45.6138C33.3278 45.7216 33.6131 45.7754 33.9006 45.7721H44.3432C44.9196 45.7721 45.4724 45.5429 45.88 45.1353C46.2876 44.7277 46.5166 44.1751 46.5166 43.5987V39.7513C46.5166 39.3766 46.1918 38.9019 45.5673 38.4272C44.8975 37.9107 44.1339 37.5291 43.3189 37.3031L40.1712 36.4288C40.0111 36.3821 39.8752 36.2756 39.7915 36.1315C39.7078 35.9873 39.6827 35.8164 39.7215 35.6542C39.7444 35.5755 39.7827 35.502 39.834 35.438C39.8854 35.3741 39.9488 35.321 40.0207 35.2816C40.0927 35.2421 40.1716 35.2173 40.2532 35.2084C40.3347 35.1995 40.4172 35.2068 40.4959 35.2297L43.6437 36.104C44.6184 36.3714 45.5338 36.8207 46.3417 37.4282C46.7392 37.6826 47.0739 38.0239 47.3205 38.4263C47.5672 38.8287 47.7193 39.2816 47.7657 39.7513V43.5987C47.7657 44.5064 47.4051 45.377 46.7632 46.0188C46.1214 46.6607 45.2509 47.0212 44.3432 47.0212Z"
      fill="#475467"
    />
    <path
      d="M37.9964 36.425C37.8307 36.425 37.6719 36.3592 37.5548 36.2421C37.4376 36.1249 37.3718 35.9661 37.3718 35.8005V34.7261C37.3718 34.5605 37.4376 34.4017 37.5548 34.2845C37.6719 34.1674 37.8307 34.1016 37.9964 34.1016C38.162 34.1016 38.3209 34.1674 38.438 34.2845C38.5551 34.4017 38.6209 34.5605 38.6209 34.7261V35.8005C38.6147 35.9641 38.547 36.1192 38.4312 36.235C38.3154 36.3509 38.16 36.4188 37.9964 36.425Z"
      fill="#475467"
    />
    <path
      d="M40.2801 36.4452C40.1145 36.4452 39.9556 36.3794 39.8385 36.2623C39.7214 36.1451 39.6556 35.9863 39.6556 35.8207V34.7213C39.6371 34.6306 39.6391 34.537 39.6613 34.4472C39.6835 34.3573 39.7255 34.2736 39.7841 34.202C39.8426 34.1303 39.9164 34.0724 40.0001 34.0327C40.0837 33.9931 40.1751 33.9727 40.2676 33.9727C40.3602 33.9727 40.4516 33.9931 40.5352 34.0327C40.6188 34.0724 40.6926 34.1303 40.7512 34.202C40.8098 34.2736 40.8517 34.3573 40.8739 34.4472C40.8961 34.537 40.8981 34.6306 40.8797 34.7213V35.8207C40.8798 35.9821 40.8175 36.1373 40.7057 36.2537C40.5939 36.3702 40.4414 36.4388 40.2801 36.4452Z"
      fill="#475467"
    />
    <path
      d="M39.1265 40.7464C38.1975 40.6157 37.3563 40.1271 36.7823 39.3851C36.2083 38.6431 35.9468 37.7064 36.0537 36.7744C36.0537 36.5495 36.0537 36.2996 36.0537 36.0498C36.0842 35.8862 36.1764 35.7406 36.3112 35.643C36.4461 35.5453 36.6132 35.5032 36.7782 35.5253C36.9398 35.55 37.0853 35.6369 37.1833 35.7677C37.2814 35.8985 37.3243 36.0625 37.3028 36.2245C37.2916 36.4075 37.2916 36.5913 37.3028 36.7744C37.3028 38.2483 38.1272 39.4973 39.1265 39.4973C40.1258 39.4973 40.9252 38.2483 40.9252 36.7744C40.9371 36.5913 40.9371 36.4075 40.9252 36.2245C40.9037 36.0625 40.9466 35.8985 41.0447 35.7677C41.1428 35.6369 41.2882 35.55 41.4498 35.5253C41.6119 35.5038 41.7759 35.5464 41.9067 35.6445C42.0375 35.7426 42.1246 35.8882 42.1493 36.0498C42.1493 36.2996 42.1493 36.5495 42.1493 36.7744C42.2573 37.6989 42.0026 38.6291 41.4389 39.3698C40.8752 40.1106 40.0463 40.604 39.1265 40.7464Z"
      fill="#475467"
    />
    <path
      d="M40.7385 27.5553C40.2882 27.5567 39.8436 27.4542 39.4394 27.2555C39.0266 27.0605 38.6603 26.7795 38.3652 26.4312C38.2051 26.2238 37.9881 26.0675 37.7407 25.9814C37.216 25.9814 36.4166 26.3062 35.5173 27.2805C35.3996 27.3746 35.2519 27.423 35.1014 27.4168C34.9509 27.4106 34.8077 27.3504 34.6981 27.247C34.5886 27.1435 34.5202 27.0041 34.5054 26.8542C34.4907 26.7042 34.5306 26.554 34.6179 26.4312C35.867 25.0572 36.9912 24.5324 38.0155 24.7573C38.5106 24.8885 38.9493 25.1779 39.2646 25.5816C39.463 25.8052 39.6999 25.9915 39.9641 26.1314C40.4637 26.3563 41.2631 26.5062 42.9619 25.5319C43.0325 25.4805 43.1133 25.4443 43.1987 25.4261C43.2842 25.4078 43.3725 25.4077 43.458 25.4257C43.5435 25.4438 43.6243 25.4795 43.6951 25.5307C43.766 25.5818 43.8253 25.6475 43.8692 25.7231C43.9131 25.7986 43.9407 25.8823 43.9502 25.9692C43.9596 26.0561 43.9507 26.1439 43.9241 26.2272C43.8974 26.3104 43.8536 26.3873 43.7955 26.4525C43.7373 26.5178 43.6661 26.5699 43.5864 26.6059C42.7354 27.1638 41.754 27.491 40.7385 27.5553Z"
      fill="#475467"
    />
    <path
      d="M29.3648 8.76864H16.6739C16.5082 8.76864 16.3494 8.70279 16.2323 8.58566C16.1151 8.46854 16.0493 8.30973 16.0493 8.14408C16.0493 7.97844 16.1151 7.81963 16.2323 7.70251C16.3494 7.58538 16.5082 7.51953 16.6739 7.51953H29.3648C29.5304 7.51953 29.6893 7.58538 29.8064 7.70251C29.9235 7.81963 29.9893 7.97844 29.9893 8.14408C29.9893 8.30973 29.9235 8.46854 29.8064 8.58566C29.6893 8.70279 29.5304 8.76864 29.3648 8.76864Z"
      fill="#475467"
    />
    <path
      d="M24.1685 12.5655H16.6739C16.5082 12.5655 16.3494 12.4997 16.2323 12.3825C16.1151 12.2654 16.0493 12.1066 16.0493 11.941C16.0493 11.7753 16.1151 11.6165 16.2323 11.4994C16.3494 11.3823 16.5082 11.3164 16.6739 11.3164H24.1685C24.3341 11.3164 24.493 11.3823 24.6101 11.4994C24.7272 11.6165 24.793 11.7753 24.793 11.941C24.793 12.1066 24.7272 12.2654 24.6101 12.3825C24.493 12.4997 24.3341 12.5655 24.1685 12.5655Z"
      fill="#475467"
    />
    <path
      d="M15.6391 21.7102H15.4143C15.2979 21.6653 15.1976 21.5867 15.1264 21.4843C15.0551 21.3819 15.0162 21.2605 15.0146 21.1357V16.6638C13.9476 15.9235 13.0687 14.9436 12.4484 13.8027C11.828 12.6618 11.4834 11.3915 11.4421 10.0935C11.4421 5.0971 16.6384 1 23.0088 1C29.3793 1 34.5506 5.0971 34.5506 10.0935C34.5506 15.0899 29.3793 19.187 23.0088 19.187C21.6661 19.1826 20.3301 18.9978 19.0367 18.6375L16.0888 21.5602C15.9644 21.6673 15.8029 21.7213 15.6391 21.7102ZM23.1337 2.19909C17.4378 2.19909 12.8161 5.7216 12.8161 10.0435C12.8652 11.1954 13.1904 12.3188 13.7643 13.3187C14.3382 14.3187 15.144 15.166 16.1138 15.7895C16.1975 15.8434 16.2664 15.9173 16.3144 16.0045C16.3623 16.0917 16.3879 16.1895 16.3886 16.289V19.5618L18.537 17.4384C18.6144 17.3541 18.7151 17.2946 18.8264 17.2679C18.9377 17.2412 19.0544 17.2483 19.1616 17.2884C20.4473 17.6899 21.7868 17.892 23.1337 17.8879C28.8047 17.8879 33.4263 14.3654 33.4263 10.0435C33.4263 5.7216 28.6798 2.19909 23.0088 2.19909H23.1337Z"
      fill="#475467"
    />
    <path
      d="M33.3034 25.2026C33.2188 25.2014 33.1353 25.1829 33.058 25.1486C32.9807 25.1142 32.9112 25.0645 32.8537 25.0025L30.5054 22.6543C29.4663 22.9556 28.3896 23.1069 27.3077 23.1038C25.8458 23.1405 24.3925 22.8706 23.0414 22.3113C21.6904 21.7519 20.4715 20.9156 19.4633 19.8564C19.3473 19.7354 19.2825 19.5741 19.2825 19.4065C19.2825 19.2389 19.3473 19.078 19.4633 18.957C19.5216 18.8966 19.5914 18.8484 19.6687 18.8155C19.746 18.7827 19.8291 18.7658 19.913 18.7658C19.997 18.7658 20.0801 18.7827 20.1573 18.8155C20.2346 18.8484 20.3044 18.8966 20.3627 18.957C21.2522 19.8815 22.325 20.6099 23.5123 21.096C24.6996 21.5821 25.9752 21.8152 27.2577 21.78C28.3429 21.7787 29.4215 21.61 30.4555 21.2802C30.5636 21.2489 30.6781 21.2463 30.7875 21.2726C30.897 21.2989 30.9978 21.3533 31.08 21.4302L32.6289 22.9791V20.4809C32.6305 20.3807 32.6536 20.282 32.6967 20.1915C32.7398 20.101 32.8019 20.0205 32.8787 19.9561C33.6594 19.4678 34.3089 18.796 34.7705 17.9992C35.232 17.2023 35.4917 16.3047 35.5268 15.3845C35.5067 14.1854 35.0745 13.0299 34.3027 12.112C34.2433 12.0491 34.1977 11.9745 34.1686 11.893C34.1396 11.8115 34.1277 11.7249 34.1339 11.6387C34.14 11.5524 34.1641 11.4685 34.2044 11.392C34.2447 11.3155 34.3005 11.2482 34.3682 11.1944C34.4359 11.1405 34.5141 11.1011 34.5977 11.0791C34.6813 11.057 34.7686 11.0528 34.854 11.0663C34.9395 11.0797 35.0212 11.1108 35.094 11.1575C35.1668 11.2041 35.2291 11.2654 35.277 11.3374C36.2305 12.472 36.7603 13.9025 36.7759 15.3845C36.7471 16.4476 36.47 17.4892 35.9668 18.4261C35.4636 19.363 34.7483 20.1695 33.878 20.7807V24.378C33.8764 24.5027 33.8374 24.6241 33.7662 24.7265C33.6949 24.8289 33.5947 24.9076 33.4783 24.9525L33.3034 25.2026Z"
      fill="#475467"
    />
  </svg>
);

export default ReactConsulting;
